/* CSS for fellesstudentsystem */
@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}
:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.not-for-ansatte {
  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red)
    input[type="button"]:not(.red):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  input[type="submit"]:not(.submit-comment-button):not(.red),
  input[type="cancel"]:not(.red),
  .header-search-expand,
  button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-color: @color-secondary !important;
    margin-left: -4px;
    &:focus,
    &:hover {
      background-color: @color-secondary--dark !important;
    }
  }
  .header-search {
    top: -56px;
    z-index: 1;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
      webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
      margin-right: 0;
    }
  }

  #head-wrapper {
    background: #ffffff url("../images/top-black-ribbon.png") repeat-x scroll
      left top;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent
        url("../uio1/images/head-dropdown-toggle-black.png") no-repeat scroll
        100% 53%;
    }
    #head {
      .uio-app-name {
        display: none;
      }
      height: 80px;
      top: 60px;
      .head-menu {
        top: -60px;
        right: 120px;
        .language {
          border-left: 1px solid @color-lines--dark;
          border-right: 1px solid @color-lines--dark;
          padding: 7px 20px 6px !important;
          a {
            color: @color-light;
          }
        }
        a {
          color: @color-light;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header {
        a.logo {
          font: 36px Arial, serif !important;
          font: 3.6rem Arial, serif !important;
          margin-left: 80px;
          margin-top: 0px;
          position: absolute;
          color: @color-neutral--dark;
          &:focus,
          &:hover {
            text-decoration: none !important;
          }
          img {
            margin-left: -80px !important;
            position: absolute;
            top: 8px;
          }
        }
        .logo-descr {
          font: 25px Arial, serif;
          font: 2.5rem Arial, serif;
          margin-left: 80px;
          margin-top: 46px;
          position: absolute;
        }
      }
    }
  }
}
#globalnav {
  margin-top: 80px;
  background: @color-primary none repeat scroll 0 0;
  ul li {
    a,
    &.vrtx-active-item a {
      color: @color-dark;
      &:hover,
      &:focus {
        background: @color-primary--light none repeat scroll 0 0;
        color: @color-dark;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;
    }
  }
}

#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-ancestor {
    display: none;
  }
  &.vrtx-child {
    a {
      border: 1px dashed #d2d6dd;
      color: @color-link;
      background: rgba(0, 0, 0, 0) url("../images/li-arrow.png") no-repeat
        scroll 10px 23px;
    }
  }
  a {
    &.vrtx-marked {
      background-color: @color-contrast !important;
      color: @color-light;
      border-width: 0 0 0 !important;
    }
    &.vrtx-after-marked {
      border-top: none !important;
    }
    &:hover,
    &:focus {
      color: @color-dark;
    }
  }
  span::before,
  a::before {
    content: " " !important;
  }
}

.remove-publishdate,
.remove-social {
  .vrtx-date-info,
  .vrtx-social-components {
    display: none;
  }
}

#main #vrtx-main-content figure.image-captioned img,
.element-border {
  border: 1px solid @color-neutral;
}

.element-no-border {
  border: 0px;
}

table th {
  font-weight: bold;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-blue.svg") no-repeat 0 3px !important;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-blue.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-blue.svg");
  }
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    background-color: @color-primary;
    *,
    a {
      color: @color-neutral--dark;
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0) url("../uio1/images/dropdown-black.png")
        no-repeat scroll right center / 22px auto;
      &:focus,
      &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #head-wrapper {
      background: none @color-light;
      height: 210px;
      #head {
        top: 20px;
        height: 162px;
        #header {
          img {
            padding: 0 10px 10px;
          }
          a.logo {
            font: 20px Arial, serif !important;
            font: 2.1rem Arial, serif !important;
          }
          .logo-descr {
            font: 16px Arial, serif;
            font: 1.6rem Arial, serif;
          }
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-primary none repeat scroll 0 0;
    }
  }

  .menu-search {
    button {
      background-color: @color-secondary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-secondary--dark;
      }
    }
  }
}

@media print {
  #main {
    margin-top: 180px !important;
  }
}

/* Mixin */

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...

@color-primary: #d2d2c7;
@color-primary--dark: darken(spin(@color-primary, -5), 10%);
@color-primary--light: #e8e6e1;
@color-secondary: #545454;
@color-secondary--dark: darken(spin(@color-secondary, -5), 10%);
@color-contrast: #0087ab;
@color-contrast--dark: darken(spin(@color-secondary, -5), 10%);
@color-link: #2771bb;

// Neutral colors
@color-neutral--dark: #2b2b2b;
@color-neutral: #949494;
@color-neutral--light: #eae9e8;
@color-lines: #f0f0f0;
@color-lines--dark: #424242;

// Suplementary colors
@color-light: #ffffff;
@color-dark: #000000;
@footer-color: @color-neutral--light;

// Spacing
@unit: 5px;
@spacing--xlarge: 100px;
@spacing--large: 40px;
@spacing: 20px;
@spacing--small: 10px;
